<template>
	<div>
		<div class="card">
			<div class="card-header justify-content-between">
				<div class="input-group input-group-flush d-flex flex-row-reverse">
					<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
					<span class="input-group-text border-0">
						<i class="fe fe-search"></i>
					</span>
				</div>

				<button class="btn btn-info" type="button" id="assignBusinessManagerButton" aria-expanded="false"
					v-if="userHasPermission('corporates011')" @click.prevent="showAssignBusinessAccountManager = true">
					Add Account Manager
				</button>
			</div>
			<b-table striped hover selectable responsive :items="filteredAccountManagers" :fields="fields" :per-page="perPage"
				:current-page="currentPage" :busy="fetchingAccountManagers" @row-clicked="viewAccountManagers"
				empty-text="No account manager has been added yet" show-empty>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty="data">
					<p class="text-center">{{ data.emptyText }}</p>
				</template>
				<template #cell(staff)="data">
					<div class="d-flex align-items-center">
						<user-avatar :user="data.item" size="sm"></user-avatar>
						<p class="ml-2">
							{{ `${data.item.fname} ${data.item.lname}` }}
						</p>
					</div>
				</template>

				<template #cell(action)="data">
					<div class="d-flex align-items-center">
						<b-button size="sm" class="mr-2" @click.prevent="unassignBusinessAccountManager(data.item.id)"
							v-if="userHasPermission('corporates013')">
							Unassign
						</b-button>
					</div>
				</template>
			</b-table>

			<div class="card-footer" v-if="filteredAccountManagers.length">
				<b-pagination v-model="currentPage" :total-rows="filteredAccountManagers.length"
					:per-page="perPage"></b-pagination>
			</div>
		</div>

		<template>
			<assign-business-account-manager-modal :show="showAssignBusinessAccountManager" :accountManagers="accountManagers"
				:companyId="companyId" @close="showAssignBusinessAccountManager = false"
				@businessAccountManager-assigned="processBusinessAccountManagerAdded"></assign-business-account-manager-modal>
		</template>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import Swal from 'sweetalert2'
import UserAvatar from '@/components/core/UserAvatar.vue'
import AssignBusinessAccountManagerModal from '@/components/modals/AssignBusinessAccountManagerModal.vue'
import { computed, ref, defineProps } from 'vue'
import {useRouter} from 'vue-router/composables'
import { axiosInstance } from '@/plugins/axios'

const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})

const fields =  ref([
      {
        key: 'staff',
        label: 'Name'
      },
      {
        key: 'phone',
        label: 'Phone'
      },
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'action',
        label: 'Action'
      }
    ])
const perPage = ref(20)
const currentPage = ref(1)
const accountManagers = ref([])
const fetchingAccountManagers = ref(true)
const search = ref('')
const showAssignBusinessAccountManager = ref(false)
const accountManager = ref(null)
const router = useRouter()



const filteredAccountManagers = computed(() => {
  const search_lowercase = search.value.toLowerCase()

return accountManagers.value.filter((accountManager) => {
  return (
    accountManager.fname.toLowerCase().includes(search_lowercase) ||
    accountManager.lname.toLowerCase().includes(search_lowercase) ||
    accountManager.email.toLowerCase().includes(search_lowercase) ||
    accountManager.phone.toLowerCase().includes(search_lowercase)
  )
})
})


const fetchBusinessAccountManagers = () => {
    fetchingAccountManagers.value = true

    axiosInstance
      .get(`/v1/corporates/${props.companyId}/managers`)
      .then((res) => {
        accountManagers.value = res.data
      })
      .finally(() => (fetchingAccountManagers.value = false))
  }

  const viewAccountManagers = (staff) => {
    router.push({ name: 'ShowStaff', params: { staffId: staff.id } })
  }

 const processBusinessAccountManagerAdded = () => {
    fetchBusinessAccountManagers()
  }

 const unassignBusinessAccountManager = async (accountManagerId) => {
    processing.value = true

    errorMessage.value = ''

    const result = await Swal.fire({
      icon: 'question',
      title: 'Please Confirm',
      text: 'Are you sure you want to unassign this account manager?',
      showConfirmButton: true,
      showCancelButton: true
    })

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(
          `/v1/corporates/${props.companyId}/managers/${accountManagerId}`
        )
        fetchBusinessAccountManagers()
        Swal.fire({
          icon: 'success',
          title: 'Account manager removed successfully',
          text: 'Business account manager has been removed from this company',
          showCloseButton: true
        })
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Failed to remove business account manager',
          text:
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : e.toString(),
          showCloseButton: true
        })
      }
    }
  }

fetchBusinessAccountManagers()
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
